import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";

const upNameClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile", // Replace with your desired API URL
  cache: new InMemoryCache(),
});

interface TokenQueryResult {
  assets: {
    icons: { url: string }[];
    lsp4TokenName: string;
    lsp4TokenSymbol: string;
    id: string;
    decimals: number;
    holders: { balance: string }[];
  }[];
}

export function useTokensInfos(ids: string[]) {
  const options = { variables: { ids } };
  return useQuery<TokenQueryResult>(tokenDocument, {
    ...options,
    client: upNameClient,
  });
}

export const tokenDocument = gql`
  query GetTokensInfos($ids: [String!]) {
    assets(where: { id_in: $ids }) {
      id
      icons(first: 1) {
        url
      }
      lsp4TokenName
      lsp4TokenSymbol
    }
  }
`;
