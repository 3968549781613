import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const SearchUsersDocument = gql`
  query SearchUserQuery($queryString: String!) {
    profiles(
      where: {
        or: [{ name_starts_with_nocase: $queryString }, { id: $queryString }]
      }
      first: 25
    ) {
      name
      id
      profileImages(first: 1) {
        url
      }
    }
  }
`;

const searchUserClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile", // Replace with your desired API URL
  cache: new InMemoryCache(),
});

export async function fetchUsersByName(queryString: string) {
  const { data, error, loading } = await searchUserClient.query({
    variables: { queryString: queryString.toLowerCase() },
    query: SearchUsersDocument,
    fetchPolicy: "cache-first",
  });

  return data.profiles;
}
