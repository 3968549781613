// Gets holds by user id

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { IUserHoldResult } from "./QueryInterfaces";
import { USER_HOLDS_PER_QUERY } from "../utils/Contants";

const UserHoldsDocument = gql`
  query UserHoldsQuery($_id: ID!, $first: Int, $skip: Int) {
    profile(id: $_id) {
      holds(first: $first, skip: $skip) {
        id
        asset {
          isLSP7
          lsp4TokenName
          lsp4TokenType
          icons(first: 1) {
            url
          }
          images(first: 1) {
            url
          }
          decimals
        }
        balance
        token {
          lsp4TokenName
          icons(first: 1) {
            url
          }
          images(first: 1) {
            url
          }
          id
          icons(first: 1) {
            url
          }
        }
      }
    }
  }
`;

const userHoldsClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile", // Replace with your desired API URL
  cache: new InMemoryCache(),
});

export interface GetUserHoldsQueryParams {
  id: string;
  first?: number;
  skip?: number;
}

export async function fetchUserHolds(
  queryParams: GetUserHoldsQueryParams
): Promise<IUserHoldResult[]> {
  const _id = queryParams.id.toLowerCase();
  const first = queryParams.first ?? USER_HOLDS_PER_QUERY;
  const skip = queryParams.skip ?? 0;

  const { data, error, loading } = await userHoldsClient.query({
    variables: { _id, first, skip },
    query: UserHoldsDocument,
    fetchPolicy: "cache-first",
  });

  return error || loading ? [] : data.profile?.holds ?? [];
}
