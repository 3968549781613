import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const TokenLogoDocument = gql`
  query TokenLogoQuery($ids: [String]) {
    assets(where: { id_in: $ids }) {
      id
      icons(first: 1) {
        url
      }
    }
  }
`;

const tokenLogoClient = new ApolloClient({
  uri: "https://up.universalswaps.io/subgraphs/name/universalswaps/universalprofile", // Replace with your desired API URL
  cache: new InMemoryCache(),
});

export async function fetchTokensLogos(ids: string[]) {
  const { data, error, loading } = await tokenLogoClient.query({
    variables: { ids },
    query: TokenLogoDocument,
    fetchPolicy: "cache-first",
  });
  return data;
}
